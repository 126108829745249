import { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Importando o CSS do Bootstrap

const sites = [
  "https://emex.hubsoft.com.br:8443/public/dashboard/4100564d-3c4b-4336-86b7-8777e30f48c9#refresh=60",
  "https://emex.hubsoft.com.br:8443/public/dashboard/a46e8092-8152-4f57-9bc9-3a47c9f961e0#refresh=60",
  "https://emex.hubsoft.com.br:8443/public/dashboard/6bf6bcf0-1ecd-4b89-aa0b-b51170b53922#refresh=60",
  "https://emex.hubsoft.com.br:8443/public/dashboard/5c2aa20b-8920-4ac3-8e13-5aea73d108f6#refresh=60"
];

export default function SiteSlider() {
  return (
    <div style={{ height: "100vh", width: "100%", margin: 0, padding: 0 }}>
      <Carousel interval={15000} fade>
        {sites.map((site, index) => (
          <Carousel.Item key={index} style={{ height: "100vh" }}>
            <iframe
              src={site}
              style={{
                width: "100%",
                height: "100%",
                border: "none"
              }}
              title={`Site Viewer ${index}`}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}
